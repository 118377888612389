import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { documentId, where } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { SsoApiResponse } from '../model/shared';
import { SsoClient, SsoClientPublicConfig } from '../model/sso-client';
import { formatApiUrl } from './utilities';
import { FirestoreService } from '../shared/services/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  
  constructor(
    private http: HttpClient,
    private firestore: FirestoreService,
  ) { }

  async getClientById(id: string) {
    const client = await this.firestore.getDocumentData<SsoClient>('clients', id);
    return client;
  }

  async getClientsByIds(ids: string[]) {
    let clients: SsoClient[] = [];
    if (ids.length > 10) {
      const chunkSize = 10;
      const batchedIds = Array.from({ length: Math.ceil(ids.length / chunkSize) }, (_, i) => 
        ids.slice(i * chunkSize, i * chunkSize + chunkSize)
      );
      for (const batch of batchedIds) {
        const tempArray = await this.firestore.getCollectionData<SsoClient[]>('clients', [
          where(documentId(), 'in', batch),
        ]);
        clients.push(...tempArray);
      }
    } else {
      clients = await this.firestore.getCollectionData<SsoClient[]>('clients', [
        where(documentId(), 'in', ids),
      ]);
    }
    return clients;
  }

  async getClients() {
    const clients = await this.firestore.getCollectionData<SsoClient[]>('clients');
    return clients;
  }

  getClientPublicConfig(clientId: string) : Observable<SsoApiResponse<SsoClientPublicConfig>> {
    return this.http.get<SsoApiResponse<SsoClientPublicConfig>>(formatApiUrl('/api/v1/config/client/' + clientId));
  }
}
