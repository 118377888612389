"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var tslib_1 = require("tslib");
var RESOURCEPATH = "/analytics/events";
var AnalyticsEvents = /** @class */function () {
  function AnalyticsEvents(apiCall) {
    this.apiCall = apiCall;
    this.apiCall = apiCall;
  }
  AnalyticsEvents.prototype.create = function (params) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
      return tslib_1.__generator(this, function (_a) {
        return [2 /*return*/, this.apiCall.post(this.endpointPath(), params)];
      });
    });
  };
  AnalyticsEvents.prototype.endpointPath = function (operation) {
    return "".concat(AnalyticsEvents.RESOURCEPATH).concat(operation === undefined ? "" : "/" + encodeURIComponent(operation));
  };
  Object.defineProperty(AnalyticsEvents, "RESOURCEPATH", {
    get: function () {
      return RESOURCEPATH;
    },
    enumerable: false,
    configurable: true
  });
  return AnalyticsEvents;
}();
exports.default = AnalyticsEvents;
