"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var tslib_1 = require("tslib");
var RESOURCEPATH = "/operations";
var Operations = /** @class */function () {
  function Operations(apiCall) {
    this.apiCall = apiCall;
  }
  Operations.prototype.perform = function (operationName, queryParameters) {
    if (queryParameters === void 0) {
      queryParameters = {};
    }
    return tslib_1.__awaiter(this, void 0, void 0, function () {
      return tslib_1.__generator(this, function (_a) {
        return [2 /*return*/, this.apiCall.post("".concat(RESOURCEPATH, "/").concat(operationName), {}, queryParameters)];
      });
    });
  };
  return Operations;
}();
exports.default = Operations;
