"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var tslib_1 = require("tslib");
var RESOURCEPATH = "/conversations/models";
var ConversationModels = /** @class */function () {
  function ConversationModels(apiCall) {
    this.apiCall = apiCall;
    this.apiCall = apiCall;
  }
  ConversationModels.prototype.create = function (params) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
      return tslib_1.__generator(this, function (_a) {
        return [2 /*return*/, this.apiCall.post(this.endpointPath(), params)];
      });
    });
  };
  ConversationModels.prototype.retrieve = function () {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
      return tslib_1.__generator(this, function (_a) {
        return [2 /*return*/, this.apiCall.get(this.endpointPath())];
      });
    });
  };
  ConversationModels.prototype.endpointPath = function (operation) {
    return "".concat(ConversationModels.RESOURCEPATH).concat(operation === undefined ? "" : "/" + encodeURIComponent(operation));
  };
  Object.defineProperty(ConversationModels, "RESOURCEPATH", {
    get: function () {
      return RESOURCEPATH;
    },
    enumerable: false,
    configurable: true
  });
  return ConversationModels;
}();
exports.default = ConversationModels;
